import Proj1 from "../assets/proj1.jpg";
import Proj2 from "../assets/proj2.png";
import Proj3 from "../assets/proj3.png";

export const ProjectList = [
  {
    name: "Music Data Insights",
    image: Proj1,
    skills: "ASP.NET, C#, SQL, Javascript",
    githubUrl: "https://github.com/jessewashburn/Classical_Guitar_Music_Directory"
  },
  {
    name: "TCG Assistant",
    image: Proj2,
    skills: "Flutter, Dart",
    githubUrl: "https://github.com/jessewashburn/TCG_Assistant"
  },
  {
    name: "AI Study App",
    image: Proj3,
    skills: "Python, Flutter, Dart, AI",
    githubUrl: "https://github.com/jessewashburn/AI-Study-App"
  },
];
